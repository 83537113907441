<template>
  <div class="invoice-view">
    <v-card flat>
      <ReportViewBar :title="`Sale By Salesman`" :start="start" :end="end" />

      <v-card-text>
        <table class="center">
          <tbody class="report-container">
            <Invoice
              :SalesRepUserId="repId"
              :start="start"
              :end="end"
            ></Invoice>
          </tbody>
        </table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
const Invoice = () => import("@/modules/ReportView/GlobalReport/Invoice.vue");

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      repId: null,
      start: null,
      end: null,
      isLoading: true,
    };
  },
  created() {
    this.repId = this.$route.params.id;
    this.start = this.$route.query.start;
    this.end = this.$route.query.end;
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
  },
  components: { Invoice, ReportViewBar },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
