<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <PrintViewBar v-if="Invoice" :showEmailBtn="showEmailBtn" :id="Invoice.id" type="invoice"
          :title="`Invoice # ${this.Invoice.localId}`" :postfix="`view?key=${currentBusiness.code}`"
          :name="currentBusiness.name" :email="Invoice.Customer.email" :obj="Invoice.Customer"
          :text="`Invoice from ${currentBusiness.name} click the link to view`" />

        <v-container fluid>
          <v-card-text class="pb-0">
            <div v-if="!$vuetify.breakpoint.xsOnly">
              <img class="paidImg" v-if="Invoice.due === 0" width="300" src="../../../../assets/paidStamp.png" />
              <img class="paidImg" v-if="Invoice.deletedAt" width="300"
                src="../../../../assets/deletedStamp-removebg-preview.png" />
            </div>

            <v-layout row wrap>
              <v-flex class="text-left text-capitalize">
                <div class="headline font-weight-bold">
                  {{ currentBusiness.name }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.address }} {{ currentBusiness.city }},
                  <span class="text-uppercase">{{ currentBusiness.state }}
                  </span>
                  {{ currentBusiness.zip }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.phoneNumber }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.email }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.website }}
                </div>

                <div class="mt-2 display-3 font-weight-black blue--text">
                  INVOICE
                </div>
                <div class="font-weight-regular">
                  <div class="font-weight-medium">BILL TO</div>
                  <div class="font-weight-bold">
                    {{ Invoice.Customer.name }}
                  </div>
                  <div>{{ Invoice.Customer.address }}</div>
                  <div v-if="Invoice.Customer.city">
                    {{ Invoice.Customer.city }},<span class="text-uppercase">{{ Invoice.Customer.state }}
                    </span>
                    {{ Invoice.Customer.zip }}
                  </div>
                </div>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex shrink class="text-right">
                <div v-if="!$vuetify.breakpoint.xsOnly">
                  <img v-if="currentBusiness.imageUrl" height="120" class="logo mb-3" :src="currentBusiness.imageUrl"
                    alt="" />
                </div>
                <div v-if="!$vuetify.breakpoint.xsOnly" class="barcode mt-2">
                  {{ Invoice.localId }}
                </div>
                <div class="font-weight-regular">
                  <v-layout row>
                    <v-flex>
                      <div class="text-right">INVOICE #</div>
                      <div class="text-right">DATE:</div>
                      <div class="text-right">SALES REP:</div>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex shrink>
                      <div class="text-left">{{ Invoice.localId }}</div>
                      <div class="text-left">
                        {{ Invoice.date | moment("MM/DD/YYYY") }}
                      </div>
                      <div class="text-left" v-if="Invoice.SalesRep">
                        {{ Invoice.SalesRep.name }}
                      </div>
                      <div class="text-left" v-else>
                        {{ Invoice.User.name }}
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text class="pb-0" v-if="Invoice.Customer.showBalance">
            <v-layout row wrap>
              <v-spacer></v-spacer>
              <v-flex shrink>
                <div class="headline font-weight-medium text-right blue--text">
                  BALANCE
                </div>
                <div class="display-2 font-weight-light text-right">
                  {{ Invoice.Customer.balance | currency }}
                </div>
                <br />
              </v-flex>
            </v-layout>
          </v-card-text>
          <table class="center">
            <!-- <v-divider class="mx-3"></v-divider> -->
            <tbody class="report-container">
              <v-card-text class="pt-0">
                <v-data-table dense :headers="headers" sort-by="name" :itemsPerPage="Invoice.InvoiceDetails.length"
                  hide-default-footer :items="Invoice.InvoiceDetails">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-center">{{ item.totalQty }}</td>
                      <td class="text-right">
                        <span>{{ item.price | currency }}
                          <span v-if="showCost">/{{ item.cost | currency }}</span>
                        </span>
                      </td>
                      <td class="text-right">
                        {{ (item.price * item.totalQty) | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
          <v-card-text class="pr-4">
            <v-divider></v-divider>
            <v-row>
              <v-col cols="3">
                <span v-if="showEmailBtn" @click="showCost = !showCost" class="pointer font-weight-medium">
                  {{ totalQty }} ITEM(S) SOLD
                </span>
                <span v-else @click="showCost = !showCost" class="font-weight-medium">
                  {{ totalQty }} ITEM(S) SOLD
                </span>

                <div class="signature">
                  <v-divider></v-divider>
                  <div>Signature</div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="7">
                <v-row>
                  <v-col>
                    <div class="font-weight-medium text-right">SUBTOTAL:</div>
                    <div class="font-weight-medium text-right">TOTAL PAID:</div>
                    <div class="font-weight-medium text-right">
                      INVOICE DUE AMOUNT:
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <div class="font-weight-medium text-right">
                      {{ Invoice.total | currency }}
                    </div>
                    <div class="font-weight-medium text-right">
                      {{ Invoice.payment | currency }}
                    </div>
                    <div class="font-weight-medium text-right">
                      {{ Invoice.due | currency }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="font-weight-medium">POLICY</div>
            <v-divider></v-divider>
            <div style="line-height: 12px">
              <small>{{ currentBusiness.policy }}</small>
            </div>
            <v-col align="right">
              <img width="100" src="@/assets/images/poweredbylogo.png" />
            </v-col>
          </v-card-text>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import PrintViewBar from "@/components/PrintViewBar";

import invoiceService from "@/modules/Invoice/service.js";
import Hashids from "hashids";
import { mapGetters, mapActions } from "vuex";
const hashids = new Hashids();

export default {
  data() {
    return {
      showCost: false,
      showEmailBtn: false,
      isLoading: true,
      Invoice: {
        InvoiceDetails: [],
      },
      headers: [
        {
          text: "ITEM NAME",
          align: "left",
          value: "productDetail",
          sortable: false,
        },
        {
          text: "QTY",
          align: "center",
          value: "qty",
          sortable: false,
        },
        {
          text: "RATE",
          align: "right",
          value: "rate",
          sortable: false,
        },
        {
          text: "TOTAL",
          align: "right",
          value: "total",
          sortable: false,
        },
      ],
      totalQty: 0,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
  },
  created() {
    if (this.$route.meta.public) {
      this.loadPublicData();
    } else {
      this.loadData();
      if (!this.currentBusiness) this.initUser();
    }
  },
  components: { PrintViewBar },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    loadPublicData() {
      const id = hashids.decode(this.$route.params.id);
      const key = this.$route.query.key;
      return invoiceService
        .publicGetById(id, { invoice: true, key: key })
        .then((response) => {
          // console.log("response invoice", response);
          this.initBusiness(response.business);
          this.$Progress.finish();
          this.Invoice = response.data;
          document.title = `${this.currentBusiness.name} - Invoice # ${this.Invoice.localId}`;
          if (this.Invoice.InvoiceDetails) {
            this.Invoice.InvoiceDetails.map((row) => {
              this.totalQty += Math.abs(parseInt(row.totalQty));
            });
          }
          this.isLoading = false;
        });
    },
    loadData() {
      const id = hashids.decode(this.$route.params.id);

      return invoiceService
        .getByIdDeleted(id, { invoice: true })
        .then((response) => {
          // console.log("response", response);
          this.Invoice = response.data;
          if (this.Invoice ?.Customer ?.email) {
            this.showEmailBtn = true;
          }
          if (this.Invoice.InvoiceDetails) {
            this.Invoice.InvoiceDetails.map((row) => {
              this.totalQty += Math.abs(parseInt(row.totalQty));
            });
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
