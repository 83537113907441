<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"> </v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <PrintViewBar
          v-if="Purchase"
          :showEmailBtn="showEmailBtn"
          :id="Purchase.id"
          type="invoice"
          :title="`Purchase # ${this.Purchase.localId}`"
          :postfix="`view?key=${currentBusiness.code}`"
          :name="currentBusiness.name"
          :email="Purchase.Vendor.email"
          :obj="Purchase.Vendor"
          :text="`Invoice from ${currentBusiness.name} click the link to view`"
        />

        <v-container fluid>
          <v-card-text class="pb-0">
            <v-layout row wrap>
              <v-flex class="text-left text-capitalize">
                <div class="headline font-weight-bold">
                  {{ currentBusiness.name }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.address }} {{ currentBusiness.city }},
                  <span class="text-uppercase"
                    >{{ currentBusiness.state }}
                  </span>
                  {{ currentBusiness.zip }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.phoneNumber }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.email }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.website }}
                </div>
              </v-flex>
              <v-spacer> </v-spacer>
              <v-flex shrink class="text-right">
                <span class="font-weight-medium">Date: </span>
                <span>{{
                  Purchase.date | moment("MM/DD/YYYY LT")
                }}</span>
                <br />
                <span class="font-weight-medium">Created By: </span>
                <span class="text-capitalize">{{ Purchase.User.name }}</span>
                <br />
                <v-switch
                  v-model="showBarcode"
                  hide-details
                  class="no-print"
                  :label="showBarcode ? 'Hide Barcode' : 'Show Barcode'"
                >
                </v-switch>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text>
            <v-layout row wrap>
              <v-flex class="text-left">
                <div class="headline font-weight-bold blue--text">
                  <span v-if="checkRightStatus(40)">
                    {{ Purchase.Vendor.name }}</span
                  >
                  <span v-else>*********</span>
                </div>
                <div v-if="checkRightStatus(40)">
                  <span class="font-weight-medium">Phone Number: </span>
                  <span v-if="Purchase.Vendor.phoneNumber">{{
                    Purchase.Vendor.phoneNumber
                  }}</span>
                  <span v-else>Not Set</span>
                  <div>
                    <div>{{ Purchase.Vendor.address }}</div>
                    <div v-if="Purchase.Vendor.city">
                      {{ Purchase.Vendor.city }}, {{ Purchase.Vendor.state }}
                      {{ Purchase.Vendor.zip }}
                    </div>
                  </div>
                </div>
              </v-flex>
              <v-spacer> </v-spacer>
              <v-flex shrink>
                <div class="headline font-weight-medium text-right blue--text">
                  Total
                </div>
                <div class="display-2 font-weight-light text-right">
                  {{ Purchase.total | currency }}
                </div>
                <br />
              </v-flex>
            </v-layout>
          </v-card-text>
          <table class="center">
            <tbody class="report-container">
              <v-card-text>
                <v-data-table
                  class="elevation-0 pa-0"
                  :headers="headers"
                  :dense="!showBarcode"
                  hide-default-footer
                  :itemsPerPage="Purchase.PurchaseDetails.length"
                  :items="Purchase.PurchaseDetails"
                >
                  <template v-slot:item="{ item }">
                    <tr
                      :class="{
                        color: $route.query.serialNumber === item.imeiNumber,
                      }"
                    >
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-left">
                        <div>
                          <div v-if="showBarcode" class="row-barcode">
                            *{{ item.imeiNumber }}*
                          </div>
                          <div v-else>{{ item.imeiNumber }}</div>
                        </div>
                      </td>
                      <td class="text-center">{{ item.qty }}</td>
                      <td class="text-right">
                        {{ item.cost | currency }}
                      </td>
                      <td class="text-right">
                        {{ (item.cost * item.qty) | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
            <tfoot class="report-footer">
              <tr>
                <td>
                  <v-card-text class="pr-4">
                    <v-divider> </v-divider>
                    <v-layout row wrap class="mt-2">
                      <v-flex shrink>
                        <v-layout row>
                          <v-flex>
                            <span class="font-weight-medium">Rows: </span>
                          </v-flex>
                          <v-spacer> </v-spacer>
                          <v-flex shrink class="font-weight-medium">
                            {{ Purchase.PurchaseDetails.length }}
                          </v-flex>
                        </v-layout>
                        <!-- <v-btn color="info" rounded class="no-print" @click="print()">Print</v-btn> -->
                      </v-flex>
                      <v-spacer> </v-spacer>
                      <v-flex xs12 sm3 shrink>
                        <v-layout row>
                          <v-flex>
                            <div class="font-weight-medium text-right">
                              Total Qty:
                            </div>
                            <div class="font-weight-medium text-right">
                              Total:
                            </div>
                          </v-flex>
                          <v-spacer> </v-spacer>
                          <v-flex shrink class="font-weight-medium">
                            <div class="font-weight-medium text-right">
                              {{ totalQty }}
                            </div>
                            <div class="font-weight-medium text-right">
                              {{ Purchase.total | currency }}
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </td>
              </tr>
            </tfoot>
          </table>
        </v-container>
      </v-card>
    </div>

    <v-card-text> </v-card-text>
  </div>
</template>

<script>
import purchaseService from "../service";
import { mapGetters, mapActions } from "vuex";
import PrintViewBar from "@/components/PrintViewBar";
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  data() {
    return {
      showBarcode: false,
      isLoading: true,
      showEmailBtn: false,
      Purchase: null,
      headers: [
        {
          text: "Name",
          align: "left",
          value: "productDetail",
          sortable: false,
        },
        {
          text: "Serial Number",
          align: "left",
          value: "serialNumber",
          sortable: false,
        },
        {
          text: "Quantity",
          align: "center",
          value: "qty",
          sortable: false,
        },
        {
          text: "Amount",
          align: "right",
          value: "rate",
          sortable: false,
        },
        {
          text: "Total",
          align: "right",
          value: "total",
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.loadData();
    this.initUser();
  },
  components: { PrintViewBar },
  computed: {
    ...mapGetters("global", [
      "currentUser",
      "checkRightStatus",
      "currentBusiness",
    ]),
    totalQty() {
      let value = 0;
      this.Purchase.PurchaseDetails.map((row) => {
        value += row.qty;
      });
      return value;
    },
  },
  methods: {
    ...mapActions("global", ["initUser", "setPageTitle"]),
    print() {
      window.print();
    },
    loadData() {
      // const id = this.$route.params.id;
			
      const id = hashids.decode(this.$route.params.id);
      this.$Progress.start();
      return purchaseService.getById(id).then((response) => {
        // console.log("response purhcase", response);
        this.$Progress.finish();
        this.Purchase = response.data;
        if (this.Purchase.Vendor.email) {
          this.showEmailBtn = true;
        }
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
.color {
  background-color: yellow;
}
/* thead.report-header {
   display: table-header-group;
}

tfoot.report-footer {
   display:table-footer-group;
}

tabel.report-container {
    page-break-after: always;
} */
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
