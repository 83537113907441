<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>

    <div v-else>
      <v-card flat>
        <PrintViewBar
          v-if="Payment"
          :showEmailBtn="showEmailBtn"
          :id="Payment.id"
          type="invoice"
          :title="`Payment # ${this.Payment.localId}`"
          :postfix="`view?key=${currentBusiness.code}`"
          :name="currentBusiness.name"
          :email="Payment.Vendor.email"
          :obj="Payment.Vendor"
          :text="`${currentBusiness.name} Payment click here to view`"
        />

        <v-card-text class="pb-0">
          <v-layout row wrap>
            <v-flex class="text-left text-capitalize">
              <div class="headline font-weight-bold">
                {{ currentBusiness.name }}
              </div>
              <div class="font-weight-medium">
                {{ currentBusiness.address }} {{ currentBusiness.city }},
                <span class="text-uppercase">{{ currentBusiness.state }} </span>
                {{ currentBusiness.zip }}
              </div>
              <div class="font-weight-medium">
                {{ currentBusiness.phoneNumber }}
              </div>
              <div class="font-weight-medium text-lowercase">
                {{ currentBusiness.email }}
              </div>
              <div class="font-weight-medium text-lowercase">
                {{ currentBusiness.website }}
              </div>

              <div class="mt-2 display-2 font-weight-black blue--text">
                PAYMENT RECEIPT
              </div>
              <div class="font-weight-regular">
                <div class="font-weight-medium">BILL TO</div>
                <div>{{ Payment.Vendor.name }}</div>
                <div>{{ Payment.Vendor.address }}</div>
                <div v-if="Payment.Vendor.city">
                  {{ Payment.Vendor.city }},<span class="text-uppercase"
                    >{{ Payment.Vendor.state }}
                  </span>
                  {{ Payment.Vendor.zip }}
                </div>
              </div>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex shrink class="text-right">
              <div v-if="!$vuetify.breakpoint.xsOnly">
                <img
                  height="120"
                  class="logo"
                  :src="currentBusiness.imageUrl"
                  alt=""
                />
              </div>
              <!-- <div class="barcode">
                {{ Payment.localId }}
              </div> -->
              <div class="font-weight-regular">
                <v-layout row>
                  <v-flex>
                    <div class="text-right">INVOICE #</div>
                    <div class="text-right">DATE:</div>
                    <div class="text-right">SALES REP:</div>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex shrink>
                    <div class="text-left">{{ Payment.localId }}</div>
                    <div class="text-left">
                      {{ Payment.date | moment("MM/DD/YYYY") }}
                    </div>
                    <div class="text-left text-capitalize">
                      {{ Payment.User.name }}
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text class="pb-0">
          <v-layout row wrap>
            <v-spacer></v-spacer>
            <v-flex shrink>
              <div class="headline font-weight-medium text-right blue--text">
                BALANCE
              </div>
              <div class="display-2 font-weight-light text-right">
                {{ Payment.Vendor.balance | currency }}
              </div>
              <br />
            </v-flex>
          </v-layout>
        </v-card-text>
        <table class="center">
          <!-- <v-divider class="mx-3"></v-divider> -->
          <tbody class="report-container">
            <v-card-text>
              <v-data-table
                dense
                :headers="headers"
                :itemsPerPage="Payment.PurchasePaymentDetails.length"
                hide-default-footer
                :items="Payment.PurchasePaymentDetails"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-left">
                      <a href="#" @click="viewInvoice(item)">{{
                        item.Purchase.localId
                      }}</a>
                    </td>
                    <td class="text-right">
                      {{ item.amount | currency }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </tbody>
        </table>
        <div class="center">
          <v-card-text>
            <v-divider></v-divider>
            <v-layout row wrap class="mt-2">
              <v-spacer></v-spacer>
              <v-flex class="pr-10">
                <v-layout row>
                  <v-flex>
                    <div class="font-weight-medium text-right">TOTAL:</div>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex shrink class="font-weight-medium">
                    <div class="font-weight-medium text-right">
                      {{ Payment.total | currency }}
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import paymentService from "../service";
import { mapGetters, mapActions } from "vuex";
import PrintViewBar from "@/components/PrintViewBar";

export default {
  data() {
    return {
      isLoading: true,
      showEmailBtn: false,
      Payment: {
        PurchasePaymentDetails: [],
      },
      headers: [
        {
          text: "INVOICE NUMBER",
          align: "left",
          value: "productDetail",
          sortable: false,
        },
        {
          text: "AMOUNT",
          align: "right",
          value: "total",
          sortable: false,
        },
      ],
      totalQty: 0,
    };
  },
  created() {
    this.loadData();
    this.initUser();
  },
  components: { PrintViewBar },
  computed: {
    ...mapGetters("global", ["currentUser", "currentBusiness"]),
  },
  methods: {
    ...mapActions("global", ["initUser"]),
    print() {
      window.print();
    },
    viewInvoice(item) {
      let routeData = null;
      if (this.$route.meta.public) {
        routeData = this.$router.resolve({
          path: `/public/purchase/${item.PurchaseId}/view`,
        });
      } else {
        routeData = this.$router.resolve({
          path: `/global/purchase/${item.PurchaseId}/view`,
        });
      }
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    loadData() {
      const id = this.$route.params.id;
      this.$Progress.start();
      return paymentService.getById(id).then((response) => {
        // console.log("response purchase payment", response);
        this.$Progress.finish();
        this.Payment = response.data;
        if (this.Payment.Vendor.email) {
          this.showEmailBtn = true;
        }
        if (this.Payment.PurchasePaymentDetails) {
          this.Payment.PurchasePaymentDetails.map((row) => {
            this.totalQty += parseInt(row.qty);
          });
        }
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.signature {
  margin-top: 100px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
