<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>

    <div v-else>
      <v-card flat>
        <PrintViewBar v-if="customer" :showEmailBtn="showEmailBtn" :id="customer.id" type="invoice"
          title="Open Invoie List" :postfix="`openInvoice?key=${currentBusiness.code}`" :name="currentBusiness.name"
          :email="customer.email" :obj="customer"
          :text="`Invoice from ${currentBusiness.name} click the link to view`" />

        <v-container fluid>
          <v-card-text class="pb-0">
            <v-layout row wrap>
              <v-flex class="text-left text-capitalize">
                <div class="headline font-weight-bold">
                  {{ currentBusiness.name }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.address }} {{ currentBusiness.city }},
                  <span class="text-uppercase">{{ currentBusiness.state }}
                  </span>
                  {{ currentBusiness.zip }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.phoneNumber }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.email }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.website }}
                </div>
                <div v-if="!$vuetify.breakpoint.xsOnly" class="mt-1 display-3 font-weight-black blue--text">
                  OPEN INVOICE
                </div>
                <div v-else class="mt-1 display-1 font-weight-black blue--text">
                  OPEN INVOICE
                </div>

                <div class="font-weight-regular">
                  <!-- <div class="font-weight-medium">BILL TO</div> -->
                  <div>{{ customer.name }}</div>
                  <div>{{ customer.address }}</div>
                  <div v-if="customer.city">
                    {{ customer.city }},<span class="text-uppercase">{{ customer.state }}
                    </span>
                    {{ customer.zip }}
                  </div>
                </div>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex shrink class="text-right">
                <div v-if="!$vuetify.breakpoint.xsOnly">
                  <img class="logo" :src="currentBusiness.imageUrl" alt="" />
                </div>
                <div v-if="customer.showBalance">
                  <v-layout row wrap>
                    <v-spacer></v-spacer>
                    <v-flex shrink>
                      <div class="
                          headline
                          font-weight-medium
                          text-right
                          blue--text
                        ">
                        BALANCE
                      </div>
                      <div class="display-2 font-weight-light text-right">
                        {{ customer.balance | currency }}
                      </div>
                      <br />
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>

          <OpenInvoiceList class="mt-3" :Invoice="Invoice" />

          <div class="center">
            <v-card-text>
              <div class="font-weight-medium">POLICY</div>
              <v-divider></v-divider>
              <div style="line-height: 12px">
                <small>{{ currentBusiness.policy }}</small>
              </div>
            </v-card-text>
          </div>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import PrintViewBar from "@/components/PrintViewBar";
import OpenInvoiceList from "@/modules/Invoice/components/OpenInvoiceList.vue";

import invoiceService from "@/modules/Invoice/service";
import customerService from "@/modules/Customer/service";
import Hashids from "hashids";
const hashids = new Hashids();
import { mapGetters, mapActions } from "vuex";

export default {
  name: "open-invoice",
  data() {
    return {
      customer: null,
      showEmailBtn: false,
      isLoading: true,
      business: null,
      Invoice: null,
      headers: [
        {
          text: "DATE",
          align: "left",
          value: "productDetail",
          sortable: false,
        },
        {
          text: "ID",
          align: "center",
          value: "qty",
          sortable: false,
        },
        {
          text: "AMOUNT",
          align: "right",
          value: "rate",
          sortable: false,
        },
        {
          text: "BALANCE",
          align: "right",
          value: "total",
          sortable: false,
        },
      ],
      totalQty: 0,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
  },
  async created() {
    await this.getCustomer();
    if (this.$route.meta.public) {
      await this.loadPublicData();
    } else {
      await this.loadData();
    }
    this.isLoading = false;
    if (!this.currentBusiness) this.initUser();
    document.title = `Report - Open Invoices`;
    // this.initUser();
    // // console.log('this.$route.params', this.$route.meta)
  },
  components: { PrintViewBar, OpenInvoiceList },
  methods: {
    ...mapActions("global", ["initUser"]),
    viewInvoice(invoice) {
      const hashId = hashids.encode(invoice.id);
      let routeData = null;
      if (this.$route.meta.public) {
        routeData = this.$router.resolve({
          path: `/public/invoice/${hashId}/view`,
        });
      } else {
        routeData = this.$router.resolve({
          path: `/global/invoice/${hashId}/view`,
        });
      }

      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    getCustomer() {
      const id = hashids.decode(this.$route.params.id);
      return customerService.getById(id).then((response) => {
        this.customer = response.data;
        if (this.customer.email) this.showEmailBtn = true;
      });
    },
    print() {
      window.print();
    },
    loadPublicData() {
      const id = hashids.decode(this.$route.params.id);
      const key = this.$route.query.key;
      const where = {
        CustomerId: id,
        due: true,
        key: key,
      };
      return invoiceService.getAll(where).then((response) => {
        // // console.log("response invoice", response);
        this.$Progress.finish();
        this.Invoice = response.data;
      });
    },
    loadData() {
      const id = hashids.decode(this.$route.params.id);

      const where = {
        CustomerId: id,
        due: true,
      };

      return invoiceService.getAll(where).then((response) => {
        // console.log("invoice", response);
        this.Invoice = response.data;
        this.count = response.count;
        this.business = response.business;
        return response;
      });

      // return invoiceService.getById(id, { invoice: true }).then(response => {
      //   // console.log("response invoice", response);
      //   this.$Progress.finish();
      //   this.Invoice = response.data;
      //   if (this.customer.email) {
      //     this.showEmailBtn = true;
      //   }
      //   if (this.Invoice.InvoiceDetails) {
      //     this.Invoice.InvoiceDetails.map(row => {
      //       this.totalQty += Math.abs(parseInt(row.qty));
      //     });
      //   }
      //   this.isLoading = false;
      // });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
