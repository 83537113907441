<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>

    <div v-else>
      <v-card flat>
        <PrintViewBar v-if="PurchaseOrder" :showEmailBtn="showEmailBtn" :id="PurchaseOrder.id" type="order"
          :title="`Purchase Order # ${PurchaseOrder.localId}`" :postfix="`view?key=${currentBusiness.code}`"
          :name="currentBusiness.name" :email="PurchaseOrder.Vendor.email" :obj="PurchaseOrder.Vendor"
          :text="`Invoice from ${currentBusiness.name} click the link to view`" />

        <v-container fluid>
          <v-card-text class="pb-0">
            <v-layout row wrap>
              <v-flex class="text-left text-capitalize">
                <div class="headline font-weight-bold">
                  {{ currentBusiness.name }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.address }} {{ currentBusiness.city }},
                  <span class="text-uppercase">{{ currentBusiness.state }}
                  </span>
                  {{ currentBusiness.zip }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.phoneNumber }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.email }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.website }}
                </div>

                <div class="mt-2 display-3 font-weight-black blue--text">
                  PURCHASE ORDER
                </div>
                <div class="font-weight-regular">
                  <div class="font-weight-medium">ORDER FROM</div>
                  <div>{{ PurchaseOrder.Vendor.name }}</div>
                  <div>{{ PurchaseOrder.Vendor.address }}</div>
                  <div v-if="PurchaseOrder.Vendor.city">
                    {{ PurchaseOrder.Vendor.city }},<span class="text-uppercase">{{ PurchaseOrder.Vendor.state }}
                    </span>
                    {{ PurchaseOrder.Vendor.zip }}
                  </div>
                </div>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex shrink class="text-right">
                <div v-if="!$vuetify.breakpoint.xsOnly">
                  <img height="120" class="logo" :src="currentBusiness.imageUrl" alt="" />
                </div>
                <div class="barcode">{{ PurchaseOrder.localId }}</div>
                <div class="font-weight-regular">
                  <v-layout row>
                    <v-flex>
                      <div class="text-right">ORDER #</div>
                      <div class="text-right">DATE:</div>
                      <div class="text-right">CREATED BY:</div>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex shrink>
                      <div class="text-left">{{ PurchaseOrder.localId }}</div>
                      <div class="text-left">
                        {{ PurchaseOrder.date | moment("MM/DD/YYYY") }}
                      </div>
                      <div class="text-left">{{ PurchaseOrder.User.name }}</div>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <table class="center mt-3">
            <!-- <v-divider class="mx-3"></v-divider> -->
            <tbody class="report-container">
              <v-card-text class="pt-0">
                <v-data-table dense :headers="headers" hide-default-footer
                  :itemsPerPage="PurchaseOrder.PurchaseOrderDetails.length" :items="PurchaseOrder.PurchaseOrderDetails">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-center">{{ item.qty }}</td>
                      <td class="text-right">
                        {{ item.price | currency }}
                      </td>
                      <td class="text-right">
                        {{ (item.price * item.qty) | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
          <v-card-text class="pr-4">
            <v-divider></v-divider>
            <v-row>
              <v-col cols="3">
                <span class="font-weight-medium" v-if="PurchaseOrder.PurchaseOrderDetails">{{ totalQty }} ITEM(S)</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="7">
                <v-row>
                  <v-col>
                    <div class="font-weight-medium text-right">SUBTOTAL:</div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <div class="font-weight-medium text-right">
                      {{ PurchaseOrder.total | currency }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import orderService from "../service";
import PrintViewBar from "@/components/PrintViewBar";
import Hashids from "hashids";
const hashids = new Hashids();
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isLoading: true,
      showEmailBtn: false,
      PurchaseOrder: {
        PurchaseOrderDetails: [],
      },
      headers: [
        {
          text: "ITEM NAME",
          align: "left",
          value: "productDetail",
          sortable: false,
        },
        {
          text: "QTY",
          align: "center",
          value: "qty",
          sortable: false,
        },
        {
          text: "RATE",
          align: "right",
          value: "rate",
          sortable: false,
        },
        {
          text: "TOTAL",
          align: "right",
          value: "total",
          sortable: false,
        },
      ],
      totalQty: 0,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
  },
  components: { PrintViewBar },
  created() {
    if (this.$route.meta.public) {
      this.loadPublicData();
    } else {
      this.loadData();
      if (!this.currentBusiness) this.initUser();
    }
  },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    loadPublicData() {
      const id = hashids.decode(this.$route.params.id);
      const key = this.$route.query.key;

      return orderService.publicGetById(id, { key }).then((response) => {
        this.PurchaseOrder = response.data;
        this.initBusiness(response.business);
        if (this.PurchaseOrder.PurchaseOrderDetails) {
          this.PurchaseOrder.PurchaseOrderDetails.map((row) => {
            this.totalQty += parseInt(row.qty);
          });
        }
        this.isLoading = false;
      });
    },
    loadData() {
      const id = hashids.decode(this.$route.params.id);
      return orderService
        .getById(id)
        .then((response) => {
          // // console.log('response', response)
          this.PurchaseOrder = response.data;
          if (this.PurchaseOrder.Vendor.email) {
            this.showEmailBtn = true;
          }
          if (this.PurchaseOrder.PurchaseOrderDetails) {
            this.PurchaseOrder.PurchaseOrderDetails.map((row) => {
              this.totalQty += parseInt(row.qty);
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          // console.log('error', err)
        });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
